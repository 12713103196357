@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');
.settings_tab{
    width: 100%;
    height: 40px;
    cursor: pointer;
    padding-left: 7%;
    align-items: center;
    align-self: center;
    display: flex;
}
.profile_left_firstDiv{
    width: 100%;
    padding: 5%;
    padding-left: 5.5%;
    padding-right: 5.5%;
    border-radius: 14px;
    margin-bottom: 4.5%;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}
.addEducationView{
    background-color: #EAF2FF;
    padding-top: 3%;
    padding-bottom: 3%;
    padding-left: 5%;
    padding-right: 5%;
    border-radius: 10px;
    height: auto;
    width: 100%;
}

p.degreeTabs_Degree{
    font-size: 16.5px;
    font-family: 'Roboto', sans-serif !important;
    font-weight: 500;
    text-align: left;
    margin-bottom: 3px;
}
p.degreeTabs_Year{
    font-size: 15px;
    font-family: 'Roboto', sans-serif !important;
    font-weight: 500;
    color: #6a6a6a;
    margin-top: 0px;
    margin-bottom: 3px;
}
span.lottieDivTxt{
    text-align: left;
    font-size: 15px;
    font-family: 'Roboto', sans-serif !important;
}
@media only screen and (min-width: 768px) {
    p.lottieDivTxt2{
        padding-top: 13px;
        margin-left: -25px;
        text-align: left;
        font-size: 17px;
        font-family: 'Roboto', sans-serif !important;
    }
    p.lottieDivTxt4{
        padding-top: 18px;
        margin-left: -30px;
        text-align: left;
        font-family: 'Roboto', sans-serif !important;
        color: #0783de;
    }
    p.lottieDivTxt5{
        padding-top: 18px;
        text-align: left;
        margin-left: -15px;
        font-family: 'Roboto', sans-serif !important;
        color: #0783de;
    }
    .lottieContainer{
        margin-top: 3%;
        padding: 15px;
        border-radius: 10px;
        border: 1.6px dotted #0783de;
    }
    .lottieContainer_2{
        margin-top: 3%;
        margin-bottom: 5%;
        padding: 15px;
        border-radius: 10px;
        border: 1.6px dotted #0783de;
    }
    .lottieContainer_3{
        margin-bottom: 2%;
        width: 100%;
        padding-right: 10px;
        border-radius: 10px;
        border: 1.6px dashed #0783de;
    }
    .lottieContainer_4{
        padding-right: 10px;
        border-radius: 10px;
        cursor: pointer;
        margin-bottom: 10px;
        border: 1.6px dashed #0783de;
        margin-left: 10px;
    }
    .lottieContainer_5{
        border-radius: 10px;
        cursor: pointer;
        margin-bottom: 15px;
        border: 1.6px dashed #0783de;
        max-width: 100%;
        margin-top: 0px !important;
    }
    p.profile_bio{
        color: #676767;
        margin-top: 2px;
        margin-bottom: 6% !important;
        font-family: 'Roboto', sans-serif !important;
    }
    p.profile_eduBio{
        color: #676767;
        margin-top: 2px;
        margin-bottom: 3% !important;
        font-family: 'Roboto', sans-serif !important;
    }
    .degreeTabs{
        background-color: #fff;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
        border-radius: 10px !important;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 5%;
        padding-right: 5%;
        margin-bottom: 6%;
        width: 100%;
    }
    .profile_skyblueBtn{
        height: 50px;
        background-color: #EAF2FF;
        width: 100%;
        border-radius: 6px;
        cursor: pointer;
        padding-top: 11.5px;
    }
    .profileInforMargins{
        margin-top: 2% !important;
        margin-bottom: 2% !important;
    }
    .settings_profileImage_empty{
        height: 130px;
        object-fit: contain;
    }
    button.settings_domains_tab_active{
        background-color: #1a2a36;
        border: none;
        color: white;
        height: 47px;
        text-align: center;
        text-decoration: none;
        font-size: 16px;
        cursor: pointer;
        border-radius: 8px;
        width: 145px;
        margin-top: 1%;
        margin-left: 1.2%;
        margin-bottom: 2.5%;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
    }
    button.settings_domains_tab_inactive{
        background-color: #EAEAEA;
        border: none;
        color: #242424;
        height: 47px;
        text-align: center;
        text-decoration: none;
        font-size: 16px;
        cursor: pointer;
        border-radius: 8px;
        width: 145px;
        margin-top: 1%;
        margin-left: 1.2%;
        margin-bottom: 2.5%;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
    }
    button.settings_domains_tab_active_first{
        background-color: #1a2a36;
        border: none;
        color: white;
        height: 47px;
        text-align: center;
        text-decoration: none;
        font-size: 16px;
        cursor: pointer;
        border-radius: 8px;
        width: 145px;
        margin-top: 1%;
        margin-bottom: 2.5%;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
    }
    button.settings_domains_tab_inactive_first{
        background-color: #EAEAEA;
        border: none;
        color: #242424;
        height: 47px;
        text-align: center;
        text-decoration: none;
        font-size: 16px;
        cursor: pointer;
        border-radius: 8px;
        width: 145px;
        margin-top: 1%;
        margin-bottom: 2.5%;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
    }
    .settings_body{
        width: 100%;
        min-height: 75vh;
        padding-left: 8%;
        padding-right: 8%;
        padding-top: 3%;
        padding-bottom: 2%;
        background-color: #f5f5f5;
    }
    .dashed-hr {
        border-top: 1.8px dashed #0e0e0e; /* Set the border style to dashed and color to your preference */
        width: 100%; /* Set the width of the horizontal rule to 100% */
        margin-top: 20px;
        margin-bottom: 27px;
        margin-right: 0px;
        margin-left: 0px; /* Set margin for spacing above and below the horizontal rule */
    }      
}
@media only screen and (max-width: 767px) {
    .dashed-hr {
        display: none;
        margin: 0px; /* Set margin for spacing above and below the horizontal rule */
      }
      
    .settings_profileImage_empty{
        height: 120px;
        object-fit: contain;
    }
    p.lottieDivTxt2{
        text-align: left;
        margin-left: -10px;
        padding-top: 3px;
        font-size: 16px;
        font-family: 'Roboto', sans-serif !important;
    }
    p.lottieDivTxt4{
        text-align: left;
        margin-left: 0px;
        font-size: 16px;
        font-family: 'Roboto', sans-serif !important;
        color: #0783de;
        padding-top: 18px;
        margin-left: -10px;        
    }
    .lottieContainer{
        margin-top: 5%;
        padding: 15px;
        border-radius: 10px;
        border: 1.6px dotted #0783de;
    }
    .lottieContainer_2{
        margin-top: 5%;
        margin-bottom: 4%;
        padding: 15px;
        padding-left: 0px;
        padding-right: 10px;
        border-radius: 10px;
        border: 1.6px dotted #0783de;
    }
    .lottieContainer_3{
        margin-bottom: 4%;
        padding-top: 10px;
        margin-top: 7%;
        padding-left: 0px;
        padding-right: 10px;
        border-radius: 10px;
        border: 1.6px dashed #0783de;
    }
    .lottieContainer_4{
        padding-left: 0px;
        padding-right: 10px;
        border-radius: 10px;
        cursor: pointer;
        border: 1.6px dashed #0783de;
        margin-bottom: 10px;
        margin-left: 10px;
        margin-right: 10px;
    }
    .lottieContainer_5{
        padding-left: 0px;
        padding-right: 10px;
        border-radius: 10px;
        cursor: pointer;
        width: 100%;
        border: 1.6px dashed #0783de;
        margin-bottom: 15px;
        margin-top: 7%;
    }
    .degreeTabs{
        background-color: #fff;
        box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
        border-radius: 10px !important;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 4%;
        padding-right: 4%;
        margin-bottom: 6%;
    }
    .profile_skyblueBtn{
        background-color: #EAF2FF;
        width: 100%;
        border-radius: 6px;
        cursor: pointer;
        padding-top: 13.5px;
        padding-bottom: 1px;
    }
    p.profile_bio{
        color: #676767;
        margin-top: 2px;
        margin-bottom: 5% !important;
        font-family: 'Roboto', sans-serif !important;
    }
    p.profile_eduBio{
        color: #676767;
        margin-top: 2px;
        margin-bottom: 5% !important;
        font-size: 15px;
        font-family: 'Roboto', sans-serif !important;
    }
    .profileInforMargins{
        margin-top: 0% !important;
        margin-bottom: 0% !important;
    }
    button.settings_domains_tab_inactive{
        background-color: #EAEAEA;
        border: none;
        color: #242424;
        height: 42px;
        text-align: center;
        text-decoration: none;
        font-size: 16px;
        cursor: pointer;
        padding-left: 20px;
        padding-right: 20px;
        border-radius: 8px;
        margin-top: 1%;
        margin-left: 2.2%;
        margin-bottom: 3%;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
    }
    button.settings_domains_tab_active_first{
        background-color: #1a2a36;
        border: none;
        color: white;
        height: 42px;
        text-align: center;
        text-decoration: none;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 16px;
        cursor: pointer;
        border-radius: 8px;
        margin-top: 1%;
        margin-bottom: 3%;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
    }
    button.settings_domains_tab_inactive_first{
        background-color: #EAEAEA;
        border: none;
        color: #242424;
        height: 42px;
        text-align: center;
        text-decoration: none;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 16px;
        cursor: pointer;
        border-radius: 8px;
        margin-top: 1%;
        margin-bottom: 3%;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
    }
    button.settings_domains_tab_active{
        background-color: #1a2a36;
        border: none;
        color: white;
        height: 42px;
        text-align: center;
        text-decoration: none;
        font-size: 16px;
        cursor: pointer;
        border-radius: 8px;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 1%;
        margin-left: 2.2%;
        margin-bottom: 3%;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        
    }
    .settings_body{
        width: 100vw;
        padding-left: 5%;
        padding-right: 5%;
        padding-top: 5%;
        background-color: #f5f5f5;
    }
}
p.settings_fullName{
    font-weight: 500;
    font-family: 'Roboto', sans-serif !important;
    font-size: 20px;
    margin-top: 5px;
    text-align: center;
    margin-bottom: -15px;
}
p.settings_TabsTittle{
    font-weight: 500;
    font-family: 'Roboto', sans-serif !important;
    font-size: 20px;
    margin-bottom: 0px !important;
    text-align: left;
}
hr.profile_hr{
    margin-top: 4.5%;
    margin-bottom: 7%;
    color: #7b7b7b;
}
.BsCheckCircleFill-icon2{
    float: right;
    color: #0F8755;
    margin-top: 3px;
}
.RxCross1-icon2{
    border-radius: 100px;
    border: 1.5px solid red;
    padding: 3px;
    margin-top: 3px;
    float: right;
    color: red;
}
.profile_humanity{
    height: 20px;
    object-fit: contain;
    margin-right: 10px;
    margin-top: -2.5px;
}
p.profile_humanityTxt{
    color: #0F8755;
    margin-bottom: 9px !important;
    font-family: 'Roboto', sans-serif !important;
    font-weight: 600;
    margin-top: 0px !important;
    font-size: 14.5px;
}
p.profile_humanityTxt_black{
    color: #222;
    margin-bottom: 9px !important;
    font-family: 'Roboto', sans-serif !important;
    font-weight: 500;
    font-size: 15px;
    text-align: left;
    float: left;
}
p.profile_Signature_black{
    color: #222;
    margin-bottom: 9px !important;
    font-family: 'Roboto', sans-serif !important;
    font-weight: 500;
    font-size: 16px;
    text-align: left;
    margin-bottom: 0px !important;
}
p.profile_Signature_black_subText{
    color: #646464;
    margin-bottom: 9px !important;
    font-family: 'Roboto', sans-serif !important;
    font-weight: 400;
    font-size: 12px;
    text-align: left;
}
.profile_greenBtn{
    height: 48px;
    background-color: #0F8755;
    width: 100%;
    border-radius: 6px;
    cursor: pointer;
    margin-top: 5%;
    padding-top: 11.5px;
}
p.profile_skyblueBtn_txt{
    color: #0783de;
    font-size: 17.5px;
    font-style: normal;
    font-weight: 500;
    font-family: 'Roboto', sans-serif !important;
    text-align: center;
}
p.profile_greenBtn_txt{
    color: #fff;
    font-size: 17.5px;
    font-style: normal;
    font-weight: 500;
    font-family: 'Roboto', sans-serif !important;
    text-align: center;
}
label{
    border: 1px solid #FFFFFF !important;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
    width: 30px !important;
    height: 30px !important;
    border-radius: 35px !important;
    position: absolute !important;
    margin-top: 3% !important;
    margin-left: -25px !important;
    background-color: #136EB7 !important;
}
.image-upload > input {
    visibility:hidden;
    width:0;
    height:0
}
input[type=file] {
    display: none;
}