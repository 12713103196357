.accordion-button:not(.collapsed) {
    box-shadow: none !important;
    background-color: #0783de !important;
    color: #fff !important;
}
.accordion-button{
    box-shadow: none !important;
}

.accordion-button:not(.collapsed){
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    color: #fff !important;
}

@media only screen and (min-width: 768px) {
    .faq_body{
        width: 100%;
        min-height: 75vh;
        padding-left: 8%;
        padding-right: 8%;
        padding-top: 3%;
        padding-bottom: 2%;
        background-color: #f5f5f5;
    }
    .accordion-button{
        box-shadow: none;
        font-family: 'Rubik', sans-serif !important;
        font-size: 19px !important;
        padding-top: 20px !important;
        padding-bottom: 20px !important;
        border-radius: 10px !important;
        /* Remove box shadow for active header */
    }
    .accordion-item {
        border-bottom: none !important;
        margin-bottom: 15px;
        font-size: 17px;
        border-radius: 10px !important;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    }
    p.faq_subHeader{
        margin-top: 3.5%;
        font-size: 22px;
        font-family: 'Rubik', sans-serif !important;
        font-weight: 500;
        margin-left: 2px;
        color: #0783de;
    }
    p.faq_subTitle{
        font-family: 'Rubik', sans-serif;
        color: #818181;
        margin-bottom: 0%;
    }
}
@media only screen and (max-width: 767px) {
    .faq_body{
        width: 100vw;
        padding: 5%;
        background-color: #f5f5f5;
    }
    .accordion-button{
        box-shadow: none;
        font-family: 'Rubik', sans-serif !important;
        font-size: 17px !important;
        padding-top: 20px !important;
        padding-bottom: 20px !important;
        border-radius: 10px !important;
        /* Remove box shadow for active header */
    }
    .accordion-item {
        border-bottom: none !important;
        margin-bottom: 15px;
        font-size: 16.5px;
        border-radius: 10px !important;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    }
    p.faq_subHeader{
        margin-top: 9%;
        font-size: 17px;
        font-family: 'Rubik', sans-serif !important;
        font-weight: 500;
        margin-left: 3px;
        color: #0783de;
    }
    p.faq_subTitle{
        font-family: 'Rubik', sans-serif;
        color: #818181;
        margin-bottom: 0%;
    }
}
span.accordianBoldTxt{
    font-weight: 600;
}
span.accordianTextLink{
    color: #0783de !important;
    cursor: pointer;
    font-weight: 500;
}


  