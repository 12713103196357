@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');


.dashboard_pie{
    float: right;
}
@media screen and (max-width: 769px) {
    .dashbaord_div_1{
        width: 100vw;
        min-height: 250px;
        background-repeat: no-repeat, repeat;
        background-position: left top;
        background-image: url("../../assets/dashboard_bg.png");
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }
    .dashbaord_div_2_innerLeft{
        box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
        width: 100%;
        border-radius: 15px;
        padding: 7%;
        padding-bottom: 0% !important;
        height: 415px;
    }
    .dashbaord_div_2_innerLeft_part{
        width: 100%;
        word-wrap: break-word;
    }
    p.dashboard_pie_txt{
        font-size: 1.3em;
        font-weight: 600;
        margin-bottom: 7px !important;
    }
    p.dashboard_pie_txt_table{
        font-size: 1.3em;
        font-weight: 600;
        margin-bottom: 12px !important;
    }
    br.br_seperator{
        display: none;
    }
    .dashbaord_div_2_innerRight{
        box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
        width: 100%;
        height: 415px;
        border-radius: 15px;
        padding: 7%;
        margin-top: 12%;
        padding-bottom: 0% !important;
    }
    span.dashboard_pie_txt_left{
        font-size: 1.3em;
        font-weight: 600;
        word-wrap: break-word;
        float: left;
        margin-bottom: 15px;
        margin-left: 10px;
    }
    .dashbaord_div_2{
        width: 100vw;
        padding-left: 8%;
        padding-right: 8%;
        padding-top: 5%;
    }
    .dashbaord_div_3{
        width: 100vw;
        padding-left: 7%;
        padding-right: 7%;
        padding-top: 5%;
    }
    span.dashboard_2022_txt{
        float: right;
        font-size: 14px;
        margin-right: 12px;
        cursor: pointer;
        margin-top: 3px;
        font-family: 'Rubik', sans-serif;
    }
    span.dashboard_2022_txt_active{
        float: right;
        margin-right: 12px;
        cursor: pointer;
        font-family: 'Rubik', sans-serif;
        color: #0068B6;
        margin-top: 3px;
        font-weight: 600;
        font-size: 14px;
    }
    span.dashboard_2022_txt_first{
        float: right;
        margin-right: 12px;
        cursor: pointer;
        margin-top: 3px;
        font-size: 14px;
        font-family: 'Rubik', sans-serif;
    }
    span.dashboard_2022_txt_first_active{
        float: right;
        margin-right: 12px;
        cursor: pointer;
        margin-top: 3px;
        font-family: 'Rubik', sans-serif;
        color: #0068B6;
        font-size: 14px;
        font-weight: 600;
    }
    .dashbaord_div_4{
        width: 100vw;
        padding-left: 7%;
        padding-right: 7%;
        padding-top: 5%;
        margin-bottom: 7%;
    }
    .dashbaord_div_4_inner{
        box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
        width: 100%;
        border-radius: 15px;
        padding: 4%;
        padding-bottom: 3%;
    }
    .dashbaord_div_3_inner{
        box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
        width: 100%;
        border-radius: 15px;
        padding: 3%;
    }
    .dashbaord_div_5{
        width: 100vw;
        padding-left: 7%;
        padding-right: 7%;
        padding-top: 8%;
        margin-bottom: 10%;
    }
    .progress_mobile_th_hidden{
        display: none;
    }
    .pie_1{
        width: 85%;
        margin-left: 7.5%;
    }
    .about_div_1_inner{
        width: 100%;
        padding:10%
    }
    p.dashbaord_div_1_inner_txt{
        color:#c72c43;
        word-wrap: break-word;
        font-size: 24px;
        font-family: 'Rubik', sans-serif;
        font-weight: 600;
    }
}
@media screen and (min-width: 770px) {
    p.dashbaord_div_1_inner_txt{
        color: #c72c43;
        line-height: 54px;
        font-size: 48px;
        font-family: 'Rubik', sans-serif;
        font-weight: 600;
    }
    .about_div_1_inner{
        width: 100vw;
        height: 75vh;
        padding-top: 5%;
        padding-left: 9%;
        padding-right: 50%;
      }
    .pie_1{
        height: 100%;
    }
    .dashbaord_div_1{
        width: 100vw;
        height: 90vh;
        background-repeat: no-repeat, repeat;
        background-position: left top;
        background-image: url("../../assets/dashboard_bg.png");
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }
    .dashbaord_div_2_innerLeft{
        box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
        width: 95%;
        height: 350px;
        margin-right: 5%;
        border-radius: 15px;
        padding: 3.5%;
    }
    .dashbaord_div_2_innerLeft_part{
        float: left;
        width: 40%;
        word-wrap: break-word;
    }
    p.dashboard_pie_txt{
        font-size: 29px;
        font-weight: 600;
        word-wrap: break-word;
    }
    p.dashboard_pie_txt_table{
        font-size: 29px;
        font-weight: 600;
        word-wrap: break-word;
    }
    br.br_seperator{
        display: block;
    }
    .dashbaord_div_2_innerRight{
        box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
        width: 95%;
        height: 350px;
        margin-left: 5%;
        border-radius: 15px;
        padding: 3.5%;
    }
    span.dashboard_pie_txt_left{
        font-size: 29px;
        font-weight: 600;
        word-wrap: break-word;
        float: left;
        margin-bottom: 15px;
    }
    .dashbaord_div_2{
        width: 100vw;
        padding-left: 10%;
        padding-right: 10%;
        padding-top: 5%;
    }
    .dashbaord_div_3{
        width: 100vw;
        padding-left: 10%;
        padding-right: 10%;
        padding-top: 5%;
    }
    span.dashboard_2022_txt{
        float: right;
        margin-right: 18px;
        cursor: pointer;
        font-family: 'Rubik', sans-serif;
    }
    span.dashboard_2022_txt_active{
        float: right;
        margin-right: 18px;
        cursor: pointer;
        font-family: 'Rubik', sans-serif;
        color: #0068B6;
        font-weight: 600;
    }
    span.dashboard_2022_txt_first{
        float: right;
        margin-right: 18px;
        cursor: pointer;
        font-family: 'Rubik', sans-serif;
    }
    span.dashboard_2022_txt_first_active{
        float: right;
        margin-right: 18px;
        cursor: pointer;
        font-family: 'Rubik', sans-serif;
        color: #0068B6;
        font-weight: 600;
    }
    .dashbaord_div_4{
        width: 100vw;
        padding-left: 10%;
        padding-right: 10%;
        padding-top: 5%;
        margin-bottom: 7%;
    }
    .dashbaord_div_4_inner{
        box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
        width: 100%;
        border-radius: 15px;
        padding: 3%;
    }
    .dashbaord_div_3_inner{
        box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
        width: 100%;
        border-radius: 15px;
        padding: 3%;
    }
    .dashbaord_div_5{
        width: 100vw;
        padding-left: 10%;
        padding-right: 10%;
        padding-top: 7%;
        margin-bottom: 10%;
    }
}
p.dashboard_pie_subtxt{
    font-size: 14px;
    font-family: 'Rubik', sans-serif;
    word-wrap: break-word;
}

a.href_reports{
    color: #FFF;
}
a.href_download{
    color: #0068B6;
}
a.href_download:hover{
    color: #fff;
}


