body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

}
html, body{
  max-width: 100vw;
  overflow-x: hidden;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
::-webkit-scrollbar {display:none;}
@-moz-document url-prefix() {
  html,
  body {
    scrollbar-width: none;
  }
}
