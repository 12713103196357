@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');

p.markerTxt{
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 4px;
}
p.markerSubTxt{
    margin-bottom: 4px;
    font-weight: 500;
    font-size: 14px;
}
.cards {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}
.card:hover {
    transform: scale(1.03);
    box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
  }
  .card-body-2:hover{
    background: #f34040;
  }
  .card img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-left: 1px;
  }
  
  .card h2 {
    color: #fff;
    margin-top: 0.7em;
    line-height: 1.4;
    font-size: 1.3em;
    margin-bottom: 3px;
    margin-left: 1px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    transition: all ease-in 100ms;
    text-align: left;
  }
  .card p {
    color: #fff;
    text-align: left;
    font-size: 13.5px;
    margin-left: 1px;
  }
.card {
    background: #fff;
    width: 100% !important;
    border-radius: 0.9em;
    margin: 5px;
    border-radius: 20px !important;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
    transition: all ease 200ms;
}
p.careCards_txt{
    font-family: 'Rubik', sans-serif;
}

@media screen and (max-width: 769px) {
    .home-content-body{
        background-color: #fff;
        width: 100vw;
        margin-top: 10%;
        padding-left: 7%;
        padding-right: 7%;
    }
    .card-body-2 {
        padding: 1.3em;
        padding-top: 1.6em;
        background: #3b3b3b;
        border-radius: 20px !important;
      }
    .home_box_1_title{
        color:#000;
        font-weight: 600;
        font-size: 25px;
        margin-top: 10%;
    }
    .home_box_1_para{
        color:#3C3C3C;
        font-size: 16px;
        font-family: 'Rubik', sans-serif;
        line-height: 25px;
    }
    button.requestQuote {
        width: 120px;
        height: 40px;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        font-family: 'Rubik', sans-serif;
        background: #1a2a36;
        border: 0px solid #fff;
    }
    .home-content-body_right_img{
        height: 30px;
        object-fit: contain;
        float: right;
        margin-right: 7.5px;
    }
    .home-content-body_right_inner{
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        border-radius: 10px;
        width: 100%;
        padding: 7%;
    }
    .home-content-body_2{
        background-repeat: no-repeat, repeat;
        background-position: right top;
        background-image: url("../../assets/background.png");
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        margin-top: 12%;
        width: 100vw;
    }
    .home-content-body_2_inner1{
        width: 100vw;
        padding-top: 8%;
        padding-left: 7%;
        padding-right: 7%;
        padding-bottom: 10%;
    }
    .home_box_1_title_white{
        color:#647285 !important;
        font-weight: 600;
        font-size: 25px;
        margin-top: 10%;
    }
    .home_box_1_title_black{
        color:#222 !important;
        font-weight: 600;
        font-size: 25px;
        margin-top: 10%;
    }
    h2.home_box_1_para_white{
        color: #c72c43;
        font-size: 16px;
        font-family: 'Rubik', sans-serif;
        line-height: 25px;
    }
    .home_box_1_para_black{
        color:#222;
        font-size: 16px;
        font-family: 'Rubik', sans-serif;
        line-height: 25px;
    }
    .badge-txt{
        color:#fff;
        font-size: 15px;
        margin-top: 4%;
        text-align: center;
        font-family: 'Rubik', sans-serif;
    }
    .home-content-body_4_inner1{
        padding: 8%;
    }
    .home-content-body_right{
        margin-top: 5%;
    }
    .scroll{
        max-height: 430px;
        overflow-y: scroll;
        margin-bottom: 15px;
        cursor: pointer;
    }
    .contact-div1-subtitle-home{
        color: #747474;
        text-align: left;
        padding-top: 3px;
        margin-left: 10px;
        font-family: 'Rubik', sans-serif;
    }
    .embeddedMap{
        height: 55vh;
        width: 101.5%;
    }
}

@media screen and (min-width: 770px) {
    .home-content-body_right{
        margin-top: 12%;
    }
    .card-body-2 {
        padding: 1.3em;
        min-height: 240px;
        padding-top: 1.6em;
        background: #3b3b3b;
        border-radius: 20px !important;
      }
    .contact-div1-subtitle-home{
        color: #747474;
        text-align: left;
        padding-top: 3px;
        font-family: 'Rubik', sans-serif;
    }
    .scroll{
        max-height: 430px;
        overflow-y: scroll;
        cursor: pointer;
    }
    .home-content-body{
        background-color: #fff;
        width: 100vw;
        padding-left: 9%;
        padding-right: 9%;
        padding-top: 6%;
        padding-bottom: 6%;
    }
    .home_box_1_title{
        color:#000;
        font-weight: 600;
        font-size: 30px;
        margin-top: 10%;
    }
    .home_box_1_para{
        color:#3C3C3C;
        font-family: 'Rubik', sans-serif;
        line-height: 25px;
        text-align:justify;
    }
    button.requestQuote {
        width: 185px;
        height: 45px;
        border-radius: 5px;
        color: white;
        font-size: 17px;
        font-family: 'Rubik', sans-serif;
        margin-top: 10px;
        background: #1a2a36;
        border: 0px solid #fff;
    }
    .home-content-body_right_img{
        height: 40px;
        object-fit: contain;
        float: right;
        margin-right: 7.5px;
    }
    .home-content-body_right_inner{
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        border-radius: 10px;
        width: 90%;
        margin-left: 10%;
        padding: 6%;
    }
    .home-content-body_2{
        background-repeat: no-repeat, repeat;
        background-position: left top;
        background-image: url("../../assets/background.png");
        -webkit-background-size: contain;
        -moz-background-size: contain;
        -o-background-size: contain;
        background-size: contain;
        height: 90vh;
        width: 100vw;
    }
    .home-content-body_2_inner1{
        width: 50vw;
        height: 105vh;
        padding-top: 5%;
        padding-left: 5%;
        padding-right: 10%;
    }
    .home_box_1_title_white{
        color:#647285 !important;
        font-weight: 600;
        font-size: 28px;
        margin-top: 10%;
    }
    .home_box_1_title_black{
        color:#222 !important;
        font-weight: 600;
        font-size: 28px;
        margin-top: 10%;
    }
    h2.home_box_1_para_white{
        color: #c72c43;
        font-size: 17px;
        font-family: 'Rubik', sans-serif;
        line-height: 25px;
        text-align:justify;
    }
    .home_box_1_para_black{
        color:#222;
        font-family: 'Rubik', sans-serif;
        line-height: 25px;
        text-align:justify;
    }
    .badge-txt{
        color:#fff;
        font-size: 17px;
        margin-top: 4%;
        text-align: center;
        font-family: 'Rubik', sans-serif;
    }
    .home-content-body_4_inner1{
        width: 100%;
        height: 100vh;
        padding-top: 5%;
        padding-left: 10%;
        padding-right: 10%;
        background-color: #fff !important;
    }
    .embeddedMap{
        height: 99.5vh;
        width: 101.6%;
    }
}

body{
    scroll-behavior: smooth;
}
button.requestQuote:hover{
    background: #101921;
}
.badges-div{
    margin-top: 7%;
    margin-left: 10%;
    margin-right: 10%;
}
.contact-icon-first-home{
    margin-top: 1% !important;
}

.contact-div1-subtitle-marker{
    color: #222;
    text-align: left;
    border: 0px solid #fff;
    font-size: 14px;
    font-family: 'Rubik', sans-serif;
}
.contact-icon-second-home{
    margin-bottom: 3%;
}
a:link {
    text-decoration: none;
}
a:hover {
    text-decoration: none;
}
.in-cus-ct {
    background: #fff !important;
    border: 1.6px solid #E8E8E8 !important;
    border-radius: 5px;
    padding-left: 10px;
}
input.input_ {
    border: 1.6px solid #E8E8E8 !important;
    border-radius: 5px;
    background: #fff !important;
    padding-left: 19px !important;
    border: none;
    width: 100%;
    min-height: 55px !important;
    padding-right: 20px !important;
}
.messageInput {
    background: #F1F1F1;
    border-radius: 5px;
    padding-left: 18px;
    border: none;
    width: 100%;
    padding-top: 10px !important;
    min-height: 100px;
}
.emptyErrorMargins{
    margin-bottom: 6%;
}
.emptyErrorMargins_TextBox{
    margin-bottom: 7%;
}
textarea:focus, input:focus{
    outline: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}

p.home-content-body_right_inner_header{
    font-weight: 600;
    margin-bottom: 5px;
}

@media screen and (max-width: 769px) {
    .about_div_1{
      width: 100vw;
      min-height: 250px;
      background-repeat: no-repeat, repeat;
      background-position: left top;
      background-image: url("../../assets/background.png");
      -webkit-background-size: contain;
      -moz-background-size: contain;
      -o-background-size: contain;
      background-size: contain;
    }
    .dashbaord_div_1_inner{
      width: 100%;
      padding:10%
    }
    h1.dashbaord_div_1_inner_txt{
        color: #647285;
        font-weight: 600;
        word-wrap: break-word;
        font-size: 28px;
        font-family: 'Rubik', sans-serif;
    }
}
@media screen and (min-width: 770px) {
    .about_div_1{
      width: 100vw;
      height: 90vh;
      background-repeat: no-repeat, repeat;
      background-position: left top;
      background-image: url("../../assets/background.png");
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    }
    .dashbaord_div_1_inner{
      width: 100vw;
      height: 75vh;
      padding-top: 7%;
      padding-left: 9%;
      padding-right: 55%;
    }
    h1.dashbaord_div_1_inner_txt{
        color:#647285;
        line-height: 58px;
        font-size: 48px;
        font-weight: 600;
        font-family: 'Rubik', sans-serif;
    }
}
a.href_reports{
    color: #FFF;
}