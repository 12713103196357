@media only screen and (max-width: 767px) {
    .container-box_login{
        width: 100vw;
        padding-left: 3%;
        padding-right: 3%;
        padding-top: 10%;
        padding-bottom: 6%;
    }
    .login_containerBox_inner{
        background-color: #fff;
        padding: 6%;
        padding-top: 2%;
        padding-bottom: 3%;
        border-radius: 10px;
    }
}
@media only screen and (min-width: 768px) {
    .container-box_login{
        width: 100vw;
        padding-left: 32%;
        padding-top: 5%;
        padding-right: 32%;
        background-color: #f5f5f5;
        padding-bottom: 5%;
    }
    .login_containerBox_inner{
        background-color: #fff;
        padding: 7%;
        border-radius: 10px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    }
}

p.forgotPassword_Back{
    font-size: 17px;
    color: #136EB7;
    font-family: 'Rubik', sans-serif;
    cursor: pointer;
    font-weight: 600;
    text-align: center;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}