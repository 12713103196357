@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');
html, body{
  max-width: 100vw;
  overflow-x: hidden;
}
img.counter_svg {
  vertical-align: middle;
  height: 25px;
}
.social-icons{
  height: 30px;
  object-fit: contain;
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .items-cls{
    margin-top: 5% !important;
  }
  .footer_contant{
    background-color: #1a2a36;
    padding: 2.2%;
    padding-left: 7%;
    padding-right: 7%;
    width: 100vw;
  }
}
@media screen and (min-width: 768px) {
  .footer_contant{
    background-color: #1a2a36;
    padding: 2.2%;
    padding-left: 5%;
    width: 100vw;
  }
}
.links{
  margin-top: -5px;
}
.links_text{
  color:#fff;
  font-size: 17px;
  text-decoration: none;
  font-family: 'Rubik', sans-serif !important;
}
.info{
  color:#fff;
  margin-top: 0px;
  font-size: 17px;
  text-align: left;
  font-family: 'Rubik', sans-serif;
}
a:link {
  font-size: 17px;
  text-decoration: none;
  font-family: 'Rubik', sans-serif;
}
a:hover {
  text-decoration: none;
  color: #ebeaeb !important;
}
p.footer_col_title{
  font-size: 26px;
  color: #fff;
  margin-bottom: 15px;
}
br.counter_br{
  display: block; /* makes it have a width */
  content: ""; /* clears default height */
  margin-top: 8px; /* change this to whatever height you want it */
}