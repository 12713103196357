
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');
html{ 
    font-family: 'Roboto', sans-serif !important;
}
.transaprentModal div{
    background-color: transparent;
    border: 0px solid transparent !important;
    padding-top: 5%;
    justify-content: center;
    align-items: center;
    align-self: center;
}
.loaderText{
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 1.3em;
    color: #fff;
    margin-top: 7px;
    text-align: center;
}
.passport_empty_txt{
    font-family: 'Roboto', sans-serif;
    color: #222;
}

@media only screen and (max-width: 599px) {
    .ViewExams_appliedBtn{
        border: 1.6px solid #50c878;
        background-color: #50c878;
        width: 100%;
        box-sizing: border-box;
        height: 50px;
        border-radius: 8px;
        cursor: pointer;
        align-self: center !important;
        align-items: center !important;
        padding-top: 9.6px;
        padding-left: 7%;
        padding-right: 7%;
        margin-top: 4%;
    }
    #marginInputs {
        margin-top: 1px;
    }
    p.availablePostsTxt{
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        font-size: 20px;
        color: #252525;
        margin-bottom: 5px;
        margin-top: 20px;
    }
    .registration_right{
        margin-top: 5%;
        padding-left: 3.5%;
        padding-right: 3.5%;
        margin-bottom: 5%;
    }
    p.input_header_txt{
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 0px;
        margin-top: 20px;
        margin-left: 4px;
    }
    .admit_header_txt{
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        font-weight: 100;
        margin-bottom: 0px;
        margin-top: 20px;
        margin-left: 4px;
    }
    p.login_header_text{
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        font-size: 27px;
        color: #252525;
        margin-bottom: 3px;
    }
    p.login_header_text_margin{
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        font-size: 27px;
        margin-top: 15%;
        color: #252525;
        margin-bottom: 3px;
    }
    .container-box_register{
        width: 100vw;
        padding-left: 2%;
        padding-right: 2%;
        padding-top: 10%;
        
        background-color: #fff;
    }
    .social_button{
        box-sizing: border-box;
        width: 100%;
        height: 55px;
        border: 1.6px solid #1a2a36;
        border-radius: 8px;
        margin-bottom: 5%;
        cursor: pointer;
        align-self: center !important;
        align-items: center !important;
        padding-top: 11.5px;
        padding-left: 7%;
        padding-right: 7%;
        margin-top: 7%;
        margin-bottom: 7%;
    }
    .viewDocumentButton{
        box-sizing: border-box;
        width: 100%;
        height: 50px;
        border: 1.6px solid #1a2a36;
        border-radius: 8px;
        cursor: pointer;
        align-self: center !important;
        align-items: center !important;
        padding-top: 10.5px;
        padding-left: 7%;
        padding-right: 7%;
        margin-top: 4%;
    }
    .deleteDocumentButton{
        box-sizing: border-box;
        background-color: red;
        width: 100%;
        height: 50px;
        border: 1.6px solid red;
        border-radius: 8px;
        cursor: pointer;
        align-self: center !important;
        align-items: center !important;
        padding-top: 10.5px;
        padding-left: 7%;
        padding-right: 7%;
        margin-top: 4%;
    }
    .login_button{
        box-sizing: border-box;
        width: 100%;
        height: 58px;
        background-color: #1a2a36;
        border-radius: 8px;
        margin-top: 30px;
        cursor: pointer;
        align-self: center !important;
        align-items: center !important;
        padding-top: 14.7px;
        padding-left: 7%;
        padding-right: 7%;
    }
    .login_button_admitCard{
        box-sizing: border-box;
        width: 100%;
        height: 58px;
        background-color: #1a2a36;
        border-radius: 8px;
        margin-top: 20px;
        cursor: pointer;
        align-self: center !important;
        align-items: center !important;
        padding-top: 14.7px;
        padding-left: 7%;
        padding-right: 7%;
    }
    .login_button_disabled{
        background-color: #a9a9a9;
        box-sizing: border-box;
        width: 100%;
        height: 58px;
        border-radius: 8px;
        margin-top: 30px;
        cursor: not-allowed;
        align-self: center !important;
        align-items: center !important;
        padding-top: 14.7px;
        padding-left: 7%;
        padding-right: 7%;
        color: #757575;
    }
    .hr{
        width: 100%;
        margin-top: 10%;
        margin-bottom: 7%;
        border: 1px solid #C6C6C6;
    }
    input.emailInput{
        border-radius: 8px;
        width: 100%;
        border: 1px solid #C6C6C6;
        height: 50px;
        padding-left: 13px;
        margin-top: 5px;
        color: #222;
        font-size: 16px;
    }
    textarea.loanPurpose{
        border-radius: 8px;
        width: 100%;
        border: 1px solid #C6C6C6;
        height: 150px;
        padding-left: 13px;
        margin-top: 5px;
        color: #222;
        font-size: 16px;
        margin-bottom: 6%;
    }
    .register_containerBox_inner{
        margin-top: 3%;
        background-color: transparent;
        padding: 3.5%;
        padding-bottom: 6%;
        margin-bottom: 0%;
    }

    .registerInputMargin{
        margin-bottom: 0%;
        margin-left: 5px;
    }
    .registerInputMargin_2{
        margin-bottom: 0%;
        margin-left: 5px;
    }
    .registerInputMargin_3{
        margin-bottom: 0%;
        margin-left: 5px;
    }
    .forPC{
        display: none;
    }
    .forMobile{
        display: block;
    }
    .genderMargins{
        margin-bottom: 0%;
    }
    .calender_div{
        border-radius: 8px;
        width: 100%;
        border: 1px solid #C6C6C6;
        min-height: 50px;
        padding-left: 13px;
        margin-top: 5px;
        padding-top: 10px;
        background-color: #fff !important;
        cursor: pointer;
    }
    .changeEmail_button{
        box-sizing: border-box;
        width: 100%;
        height: 56px;
        border: 1px solid #1a2a36;
        border-radius: 8px;
        margin-top: 30px;
        cursor: pointer;
        align-self: center !important;
        align-items: center !important;
        padding-top: 14.7px;
        padding-left: 7%;
        padding-right: 7%;
    }
    .changePosts_button{
        box-sizing: border-box;
        width: 100%;
        height: 56px;
        background-color: #1a2a36;
        border: 1px solid #1a2a36;
        border-radius: 8px;
        margin-top: 10px;
        cursor: pointer;
        align-self: center !important;
        align-items: center !important;
        padding-top: 14.7px;
        padding-left: 7%;
        padding-right: 7%;
    }
    .checkPayment_button{
        box-sizing: border-box;
        width: 100%;
        height: 56px;
        border: 1px solid #1a2a36;
        border-radius: 8px;
        cursor: pointer;
        align-self: center !important;
        align-items: center !important;
        padding-top: 14.7px;
        padding-left: 7%;
        padding-right: 7%;
    }
    .passport_empty_img{
        margin-top: 27.5%;
        height: 55px;
        width: 55px;
    }  
    .emptyPasstport{
        height: 170px;
        width: 100%;
        background-color: #E2E2E2;
        border-radius: 5px;
        padding: 3px;
    }
    .emptyPasstport_Admit{
        height: 170px;
        width: 100%;
        background-color: #E2E2E2;
        border-radius: 5px;
        padding: 3px;
    }
    .emptySignature{
        height: 80px;
        width: 100%;
        background-color: #E2E2E2;
        border-radius: 5px;
        padding: 3px;
        margin-top: 20px;
    }
    .emptySignature_Admit{
        height: 80px;
        width: 100%;
        background-color: #E2E2E2;
        border-radius: 5px;
        padding: 3px;
        margin-top: 10px;
    }
    .signature_empty_img{
        margin-top: 8.5%;
        height: 30px;
        width: 30px;
    }
    .passtport_header{
        margin-top: 0%;
    }
    p.dob_txt{
        margin-top: 3px !important;
        word-wrap: break-word;
        overflow-wrap: break-word;
        color: #807f7f !important;
    }
    p.login_ForgotPasswordText{
        font-family: 'Roboto', sans-serif !important;
        font-size: 15px;
        cursor: pointer;
        margin-right: 3px;
        margin-bottom: 10px;
        margin-top: 5px;
        text-align: right;
    }
    .isNameChanged_Margins{
        margin-bottom: 0px;
    }
    .isNameChanged_Margins2{
        margin-bottom: 0px;
    }
    .emptySignature_Profile{
        height: 106px;
        width: 100%;
        background-color: #E2E2E2;
        border-radius: 5px;
        padding: 3px;
    }
    p.login_subheader_text_bankInfo{
        font-family: 'Rubik', sans-serif;
        color: #818181;
        margin-bottom: 0px;
    }
    p.confirmationText{
        font-family: 'Rubik', sans-serif;
        font-size: 15px;
        color: #222;
        font-weight: 400;
        margin-left: 4.5px;
        margin-bottom: 0%;
    }
}
.otp_input_center{
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
}
.emailVerifyHeader_register{
    color: #2ba682;
    background-color: #eaf6f2;
    padding: 5px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 100px;
    display: inline-block;
    margin-top: 5%;
    margin-bottom: 6px;
    text-align: center;
}
.otpERROR_register{
    color: #fff;
    background-color: red;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 4px;
    display: inline-block;
    margin-top: 5%;
    margin-bottom: 6px;
    font-family: 'Roboto', sans-serif !important;
    text-align: center;
    margin-bottom: 0px;
}
.OTR_SuccessfulMessage{
    color: #2ba682;
    background-color: #eaf6f2;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 10px;
    margin-top: 3%;
    margin-bottom: 6px;
    text-align: center;
    width: 100%;
    cursor: pointer;
}
p.OTR_SuccessfulMessageTxt{
    margin-bottom: 4px !important;
}
span.OTR_SuccessfulMessageSpan{
    font-weight: 600;
}
.OTR_SuccessfulMessageWarn{
    color: #242424;
    background-color: #EAEAEA;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 10px;
    margin-top: 3%;
    margin-bottom: 6px;
    text-align: center;
    width: 100%;
    cursor: pointer;
}

.passport_img{
    width: 100%;
    height: 164px;
    border-radius: 5px;
    object-fit: cover !important;
}
.signature_img{
    width: 100%;
    height: 74px;
    border-radius: 5px;
    object-fit: cover !important;
}
.signature_imgProfile{
    width: 100%;
    height: 100px;
    border-radius: 5px;
    object-fit: cover !important;
}
@media only screen and (min-width: 600px) {
    .ViewExams_appliedBtn{
        height: 45px;
        border: 1.6px solid #50c878;
        background-color: #50c878;
        width: 100%;
        border-radius: 8px;
        cursor: pointer;
        padding-top: 8px;
    }
    p.login_subheader_text_bankInfo{
        font-family: 'Rubik', sans-serif;
        color: #818181;
        margin-bottom: 4%;
    }
    textarea.loanPurpose{
        border-radius: 8px;
        width: 100%;
        border: 1px solid #C6C6C6;
        height: 150px;
        padding-left: 13px;
        margin-top: 5px;
        color: #222;
        padding-top: 10px;
        font-size: 16px;
    }
    p.dob_txt{
        margin-top: 1px;
        color: #807f7f !important;
        word-wrap: break-word;
        overflow-wrap: break-word;
    }
    p.availablePostsTxt{
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        font-size: 20px;
        color: #252525;
        margin-top: 0px !important;
        margin-bottom: 8px;
        margin-left: 6px;
    }
    .isNameChanged_Margins{
        margin-bottom: 4%;
    }
    .isNameChanged_Margins2{
        margin-bottom: 4.5%;
    }
    .isPWD_Margins{
        margin-top: 1%;
    }
    p.login_ForgotPasswordText{
        font-family: 'Roboto', sans-serif !important;
        font-size: 15px;
        cursor: pointer;
        margin-right: 3px;
        margin-bottom: 20px;
        margin-top: -5px;
        text-align: right;
    }
    .emptyPasstport{
        height: 170px;
        width: 100%;
        background-color: #E2E2E2;
        border-radius: 5px;
        padding: 3px;
        margin-bottom: 20px;
    }
    .emptyPasstport_Admit{
        height: 170px;
        width: 100%;
        background-color: #E2E2E2;
        border-radius: 5px;
        padding: 3px;
        margin-bottom: 10px;
    }
    .emptySignature{
        height: 80px;
        width: 100%;
        background-color: #E2E2E2;
        border-radius: 5px;
        padding: 3px;
        margin-bottom: 20px;
    }
    .emptySignature_Profile{
        height: 106px;
        width: 100%;
        background-color: #E2E2E2;
        border-radius: 5px;
        padding: 3px;
    }
    .emptySignature_Admit{
        height: 80px;
        width: 100%;
        background-color: #E2E2E2;
        border-radius: 5px;
        padding: 3px;
        margin-bottom: 5px;
    }
    .passtport_header{
        margin-top: 2%;
    }
    .passport_empty_img{
        margin-top: 32%;
        height: 55px;
        width: 55px;
    }
    .confirmationTXT_margins{
        margin-top: 2.5%;
        margin-bottom: 2.5%;
    }
    .confirmationTXT_margins_2{
        margin-top: 2.5%;
    }
    .signature_empty_img{
        margin-top: 5%;
        height: 35px;
        width: 35px;
    }
    .forPC{
        display: block;
    }
    .forMobile{
        display: none;
    }
    .registerInputMargin{
        margin-bottom: 5%;
    }
    .registerInputMargin_2{
        margin-bottom: 2.5%;
    }
    .registerInputMargin_3{
        margin-bottom: 4%;
    }
    p.input_header_txt{
        font-family: 'Roboto', sans-serif;
        font-size: 16.3px;
        font-weight: 500;
        margin-bottom: 0px;
        margin-left: 4px;
    }
    .admit_header_txt{
        font-family: 'Roboto', sans-serif;
        font-size: 16.3px;
        font-weight: 100;
        margin-bottom: 0px;
        margin-left: 4px;
    }
    #marginInputs {
        margin-top: 5px;
        margin-left: 4px;
    }
    p.login_header_text{
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        font-size: 27px;
        color: #252525;
        margin-bottom: 3px;
    }
    p.login_header_text_margin{
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        font-size: 27px;
        color: #252525;
        margin-top: 7%;
        margin-bottom: 3px;
    }
    .container-box_register{
        width: 100vw;
        padding-top: 4%;
        padding-right: 10%;
        padding-left: 8%;
        min-height: 100vh;
        background-color: #f5f5f5;
    }
    .registration_right{
        margin-top: 27%;
        padding-left: 7%;
    }
    .social_button{
        box-sizing: border-box;
        width: 100%;
        height: 50px;
        border: 1.6px solid #1a2a36;
        border-radius: 8px;
        margin-bottom: 5%;
        cursor: pointer;
        align-self: center !important;
        align-items: center !important;
        padding-top: 10.2px;
    }
    .viewDocumentButton{
        box-sizing: border-box;
        width: 100%;
        height: 45px;
        border: 1.6px solid #1a2a36;
        border-radius: 8px;
        margin-top: 4%;
        margin-bottom: 3%;
        cursor: pointer;
        align-self: center !important;
        align-items: center !important;
        padding-top: 7.5px;
    }
    .deleteDocumentButton{
        box-sizing: border-box;
        width: 100%;
        height: 45px;
        background-color: red;
        border: 1.6px solid red;
        border-radius: 8px;
        margin-top: 4%;
        margin-bottom: 3%;
        cursor: pointer;
        align-self: center !important;
        align-items: center !important;
        padding-top: 7.5px;
    }
    .login_button{
        box-sizing: border-box;
        width: 100%;
        height: 58px;
        background-color: #1a2a36;
        border-radius: 8px;
        margin-top: 35px;
        cursor: pointer;
        align-self: center !important;
        align-items: center !important;
        padding-top: 14.7px;
        padding-left: 7%;
        padding-right: 7%;
    }
    .login_button_admitCard{
        box-sizing: border-box;
        width: 100%;
        height: 58px;
        background-color: #1a2a36;
        border-radius: 8px;
        margin-top: 15px;
        cursor: pointer;
        align-self: center !important;
        align-items: center !important;
        padding-top: 14.7px;
        padding-left: 7%;
        padding-right: 7%;
    }
    .login_button_disabled{
        background-color: #a9a9a9;
        box-sizing: border-box;
        width: 100%;
        height: 58px;
        border-radius: 8px;
        margin-top: 35px;
        cursor: not-allowed;
        align-self: center !important;
        align-items: center !important;
        padding-top: 14.7px;
        padding-left: 7%;
        padding-right: 7%;
        color: #757575;
    }
    .changeEmail_button{
        box-sizing: border-box;
        width: 100%;
        height: 56px;
        border: 1px solid #1a2a36;
        border-radius: 8px;
        margin-top: 35px;
        cursor: pointer;
        align-self: center !important;
        align-items: center !important;
        padding-top: 14.7px;
        padding-left: 7%;
        padding-right: 7%;
    }
    .changePosts_button{
        box-sizing: border-box;
        width: 100%;
        height: 56px;
        background-color: #1a2a36;
        border: 1px solid #1a2a36;
        border-radius: 8px;
        margin-top: 10px;
        cursor: pointer;
        align-self: center !important;
        align-items: center !important;
        padding-top: 14.7px;
        padding-left: 7%;
        padding-right: 7%;
    }
    .checkPayment_button{
        box-sizing: border-box;
        width: 100%;
        height: 56px;
        border: 1px solid #1a2a36;
        border-radius: 8px;
        cursor: pointer;
        align-self: center !important;
        align-items: center !important;
        padding-top: 14.7px;
        padding-left: 7%;
        padding-right: 7%;
    }
    input.emailInput{
        border-radius: 8px;
        width: 100%;
        border: 1px solid #C6C6C6;
        height: 50px;
        padding-left: 13px;
        margin-top: 5px;
        color: #222;
        font-size: 16px;
    }
    .calender_div{
        border-radius: 8px;
        width: 100%;
        border: 1px solid #C6C6C6;
        height: 50px;
        padding-left: 13px;
        margin-top: 5px;
        padding-top: 10px;
        cursor: pointer;
        background-color: #fff !important;
    }
    .genderMargins{
        margin-bottom: 4.5%;
    }
    .hr{
        width: 100%;
        margin-top: 7%;
        margin-bottom: 7%;
        border: 1px solid #C6C6C6;
    }
    .register_containerBox_inner{
        background-color: #fff;
        padding: 5%;
        border-radius: 10px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        margin-bottom: 8.5%;
        margin-top: 6%;
    }
    p.confirmationText{
        font-family: 'Rubik', sans-serif;
        font-size: 15px;
        color: #222;
        font-weight: 400;
        margin-left: 4.5px;
        margin-bottom: 4%;
    }
}
p.modal_header_text{
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    font-size: 22px;
    color: #252525;
    margin-bottom: 3px;
}
.calender_div:hover{
    border: 1.6px solid #1a2a36;
}
p.login_subheader_text{
    font-family: 'Rubik', sans-serif;
    color: #818181;
    margin-bottom: 6%;
}
p.verifyEmail_subheader_text{
    font-family: 'Rubik', sans-serif;
    font-size: 15px;
    color: #818181;
}
p.passport_subheader_text{
    font-family: 'Rubik', sans-serif;
    font-size: 15px;
    color: #818181;
    margin-bottom: 0px;
}

p.loanPurposeText{
    font-family: 'Rubik', sans-serif;
    font-size: 16px;
    color: #222;
    font-weight: 400;
    margin-left: 4.5px;
    margin-bottom: 0%;
}
.calenderLayout{
    padding: 0px !important;
    border-radius: 0px !important;
    margin: 0px !important;
    border: 0px solid #fff !important;
    width: 100% !important;
}
p.signup_with{
    color: #777777;
    font-size: 14.5px;
    text-align: center;
    margin-top: 5%;
    margin-bottom: 10px;
    font-family: 'Rubik', sans-serif;
}
p.login_signup_ques_text{
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    text-align: center;
    letter-spacing: 0.01em;
    color: #1a2a36;
}
p.viewDocumentButton_Txt{
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #1a2a36;
}
p.deleteDocumentButton_Txt{
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #fff;
}
p.login_signup_ques_text_white{
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    text-align: center;
    letter-spacing: 0.01em;
    color: #fff;
}
p.login_signup_ques_text_blue{
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    text-align: center;
    letter-spacing: 0.01em;
    color: #1a2a36;
}
input.emailInput:focus {
    border: 1.6px solid #0783de;
    outline:none;
}
span.validateErrorTxt {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    letter-spacing: 0.01em;
    color: red;
    font-family: 'Roboto', sans-serif;
}
hr.forgotPassword_hr{
    margin-top: 10px;
    margin-bottom: 20px;
}